<template>
  <div>
    <div
      v-if="checks.every((c) => c.isSuccess)"
      class="d-flex justify-content-center"
    >
      <span class="text-success font-weight-bold">{{
        T(
          "Web.InvoiceInspectionsPage.Preview.AllLinesPassed",
          "All lines passed"
        )
      }}</span>
    </div>
    <div v-else>
      <div class="check-list">
        <div class="header">
          <span>{{ T("Web.Generic.Invoices.LineId", "Line id") }}</span>
          <span>{{
            T("Web.InvoiceInspectionsPage.PostedTo", "Posted to (infocard)")
          }}</span>
          <span>{{ T("Web.Generic.Invoices.Quantity", "Quantity") }}</span>
          <span>{{ T("Web.Generic.Invoices.UnitPrice", "Unit price") }}</span>
          <span
            >{{ T("Web.Generic.Invoices.LineTotal", "Line total") }} ({{
              T(
                "Web.InvoiceInspectionsPage.InvoicedExpected",
                "Invoiced/expected"
              )
            }})</span
          >
          <span></span>
        </div>
        <div
          class="check"
          :class="{
            'table-danger': content.includes(check.checkId),
            'table-primary': !content.includes(check.checkId),
          }"
          v-for="(check, index) in failedChecks"
          :key="index"
          @mouseenter="$emit('line-higlighed', check.lineId)"
          @mouseleave="$emit('line-higlighed', null)"
        >
          <span>{{ check.lineNo }}</span>
          <span>
            <small class="font-weight-bold"> {{ check.infocardRef }}</small>
            <br />
            <small> {{ check.infocardName }} </small></span
          >
          <span
            >{{ check.lineQuantity | toCurrency }} {{ check.lineUnit }}</span
          >
          <span>
            <div>
              <span class="font-weight-bold text-danger">{{
                check.unitPriceFound | toCurrency
              }}</span>
              /
              <span class="font-weight-bold text-primary">{{
                check.unitPriceExpected | toCurrency
              }}</span>
            </div>
            <div>
              <div class="diff-con">
                {{ T("Web.Generic.Difference") }}
                <span class="text-danger font-weight-bold">{{
                  check.unitPriceDifference | toCurrency
                }}</span
                ><span> [%]</span>
                <br />
                {{ T("Web.Generic.Tolerance") + ":" }}
                <span class="text-primary font-weight-bold">{{
                  check.differenceTolerance | toCurrency
                }}</span
                ><span> [%]</span>
              </div>
            </div>
          </span>
          <span>
            <div>
              <span class="text-danger font-weight-bold">{{
                check.lineTotalFound | toCurrency
              }}</span>
              /
              <span class="font-weight-bold text-primary">{{
                check.lineTotalExpected | toCurrency
              }}</span>
            </div>
            <div>
              <div class="diff-con">
                {{ T("Web.Generic.Difference") }}

                <span class="text-danger font-weight-bold">{{
                  check.lineTotalDifference | toCurrency
                }}</span
                ><span> [%]</span>
                <br />
                {{ T("Web.Generic.Tolerance") + ":" }}

                <span class="text-primary font-weight-bold">{{
                  check.differenceTolerance | toCurrency
                }}</span
                ><span> [%]</span>
              </div>
            </div>
          </span>
          <span>
            <input
              type="checkbox"
              :checked="content.includes(check.checkId)"
              @change="toggleIncludeCheck(check.checkId)"
              @click.stop
          /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checks: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    failedChecks() {
      return this.checks.filter((c) => !c.isSuccess);
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
    toggleIncludeCheck(checkId) {
      if (this.content.includes(checkId))
        this.content.splice(this.content.indexOf(checkId), 1);
      else this.content.push(checkId);
      this.handleInput();
    },
  },
};
</script>


<style lang="scss" scoped>
.check-list {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    padding: 0 10px;
    border-bottom: 1px solid;
    font-size: 11px;
    span {
      width: 20%;
      &:last-of-type {
        width: 5%;
      }
    }
  }
  .check {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebe9f1;
    padding: 5px 10px;

    span {
      width: 20%;
      &:last-of-type {
        width: 5%;
      }
    }

    &:hover {
      background-color: #ffe4ca;
    }
    .diff-con {
      font-size: 10px;
      padding: 4px 0px;
      margin-top: 4px;
    }
  }
}
</style>