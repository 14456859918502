<template>
  <b-overlay :show="isLoading" class="overlay" variant="transparent">
    <b-card no-body class="invoice-preview">
      <b-card-body class="invoice-padding pb-0">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <!-- Header: Left Content -->
          <div>
            <p class="font-weight-bold">
              {{ T("Web.Generic.Invoices.Reciver", "Receiver") }}
            </p>
            <p class="card-text mb-25">{{ invoiceData.customer.name }}</p>
            <p class="card-text mb-25">
              {{ invoiceData.customer.address }}
            </p>
            <p class="card-text mb-25">
              {{ invoiceData.customer.zip }} {{ invoiceData.customer.city }}
            </p>
            <p class="card-text mb-25">{{ invoiceData.customer.cvr }}</p>
          </div>

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2">
            <h4 class="invoice-title">
              {{ getDocumentTypeDisplayText(invoiceData.documentType) }}
              <span class="invoice-number"
                >#{{ invoiceData.invoiceNumber }}</span
              >
            </h4>
            <div>
              <p class="font-weight-bold">
                {{ T("Web.Generic.Invoices.Sender", "Sender") }}
              </p>
              <p class="card-text mb-25">{{ invoiceData.supplier.name }}</p>
              <p class="card-text mb-25">
                {{ invoiceData.supplier.address }}
              </p>
              <p class="card-text mb-25">
                {{ invoiceData.supplier.zip }}
                {{ invoiceData.supplier.city }}
              </p>
              <p class="card-text">{{ invoiceData.supplier.cvr }}</p>
              <br />
              <div class="invoice-date-wrapper">
                <p class="invoice-date-title">
                  {{ T("Web.Generic.Invoices.InvoiceDate", "Date") }}
                </p>
                <p class="invoice-date">
                  {{ format_date(invoiceData.issueDate) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
      <b-table-lite
        responsive
        :items="tableContent.items"
        :fields="tableContent.fields"
        primary-key="id"
        @row-clicked="handleRowClicked"
        ref="invoiceTable"
        :tbody-tr-class="invoiceLineRowClass"
      >
        <template #cell(date)="data">
          <b-card-text class="font-weight-bold mb-25">
            {{ format_date(data.item.date) }}
          </b-card-text>
        </template>
        <template #cell(text)="data">
          <b-card-text class="font-weight-bold mb-25">
            <span>{{ data.item.text }}</span>
            <br />
            <small>
              {{ data.item.description }}
              <br />
              {{ data.item.note }}
            </small>
          </b-card-text>
        </template>
        <template #cell(amount)="data">
          <b-card-text class="font-weight-bold mb-25">
            {{ data.item.amount | toCurrency }}
          </b-card-text>
        </template>
        <template #cell(priceAmount)="data">
          <b-card-text class="font-weight-bold mb-25">
            {{ data.item.priceAmount | toCurrency }}
          </b-card-text>
        </template>
        <template #cell(priceTotal)="data">
          <b-card-text class="font-weight-bold mb-25">
            {{ data.item.priceTotal | toCurrency }}
          </b-card-text>
        </template>
      </b-table-lite>
      <!-- Spacer -->
      <hr class="invoice-spacing" />
      <!-- Note -->
      <b-card-body class="invoice-padding pt-0">
        <span class="font-weight-bold">
          {{ T("Web.Generic.Invoices.InvoiceNote", "Note") }}
        </span>
        <span>{{ invoiceData.note }}</span>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BSpinner,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import FlexTable from "@/components/flex-table/FlexTable.vue";
export default {
  props: {
    invoiceId: {
      required: true,
    },
    invoiceLineRowClass: {
      required: false,
      default: function () {},
    },
  },
  components: {
    BSpinner,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BOverlay,
    FlexTable,
  },
  created() {
    this.fetchInvoice({ id: this.invoiceId, include: ["invoiceLines"] });
  },
  computed: {
    ...mapGetters({
      getInvoice: "invoices/byId",
      isLoading: "invoices/isLoading",
    }),
    invoiceData() {
      return this.getInvoice(this.invoiceId);
    },
    tableContent() {
      return {
        fields: [
          {
            key: "lineId",
            label: this.T("Web.Generic.Invoices.LineId", "Line id"),
            sortable: true,
          },
          {
            key: "date",
            label: this.T("Web.Generic.Invoices.LineDate", "Date"),
            sortable: true,
          },
          {
            key: "sku",
            label: this.T("Web.Generic.Invoices.LineSKU", "Id"),
            sortable: true,
          },
          {
            key: "text",
            label: this.T("Web.Generic.Invoices.LineDescription", "Description"),
            sortable: false,
          },
          {
            key: "amount",
            label: this.T("Web.Generic.Invoices.LineAmount", "Amount"),
            sortable: false,
          },
          {
            key: "unit",
            label: this.T("Web.Generic.Invoices.LineUnit", "Unit"),
            sortable: false,
          },
          {
            key: "priceAmount",
            label: this.T("Web.Generic.Invoices.LineUnitPrice", "Unit price"),
            sortable: false,
          },
          {
            key: "priceTotal",
            label: this.T("Web.Generic.Invoices.LineTotal", "Total price"),
            sortable: false,
          },
        ],
        items: this.invoiceData.invoiceLines,
      };
    },
  },
  methods: {
    ...mapActions({ fetchInvoice: "invoices/fetchSingle" }),
    handleRowClicked(item) {
      this.$emit("invoice-line-clicked", item);
    },
    getDocumentTypeDisplayText(type) {
      return this.T(`Web.Generic.Invoices.DocumentTypes.${type}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  min-height: 100%;
  display: flex;
}

.invoice-preview {
  min-height: 100%;
  width: 100%;
}
</style>