<template>
  <div>
    <div v-if="warnings.length == 0" class="d-flex justify-content-center">
      <span class="text-success font-weight-bold">{{
        T(
          "Web.InvoiceInspectionsPage.Preview.AllLinesPassed",
          "All lines passed"
        )
      }}</span>
    </div>
    <div v-else>
      <div class="check-list">
        <div class="header">
          <span>{{ T("Web.Generic.Invoices.LineId", "Line id") }}</span>
          <span>{{
            T("Web.InvoiceInspectionsPage.PostedTo", "Posted to (infocard)")
          }}</span>
          <span>{{ T("Web.Generic.Invoices.Quantity", "Quantity") }}</span>
          <span>{{ T("Web.Generic.Invoices.UnitPrice", "Unit price") }}</span>
          <span
            >{{ T("Web.Generic.Invoices.LineTotal", "Line total") }}</span
          >
        </div>
        <div
          class="check table-warning"
          v-for="(warning, index) in warnings"
          :key="index"
          @mouseenter="$emit('line-higlighed', warning.lineId)"
          @mouseleave="$emit('line-higlighed', null)"
        >
          <span>{{ warning.lineNo }}</span>
          <span>
            <small class="font-weight-bold"> {{ warning.infocardRef }}</small>
            <br />
            <small> {{ warning.infocardName }} </small></span
          >
          <span
            >{{ warning.lineQuantity | toCurrency }}
            {{ warning.lineUnit }}</span
          >
          <span>
            {{ warning.unitPrice | toCurrency }}
          </span>
          <span>
            {{ warning.lineTotal | toCurrency }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    warnings: {
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.check-list {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    padding: 0 10px;
    border-bottom: 1px solid;
    font-size: 11px;
    span {
      width: 20%;
    }
  }
  .check {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebe9f1;
    padding: 5px 10px;

    span {
      width: 20%;
    }

    &:hover {
      background-color: #ffe4ca;
    }
    .diff-con {
      font-size: 10px;
      padding: 4px 0px;
      margin-top: 4px;
    }
  }
}
</style>