var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('b-overlay',{attrs:{"show":_vm.submitting}},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center mt-2 mb-2"},[_c('b-spinner')],1):_c('b-row',[_c('b-col',{staticClass:"scroll-col",staticStyle:{"border-right":"2px solid #ccc"},attrs:{"cols":"5"}},[_c('h3',{staticClass:"text-muted mb-2"},[_vm._v(" "+_vm._s(_vm.T( "Web.InvoiceInspectionsPage.Preview.InspectionResults", "Inspection results" ))+" ")]),_vm._l((_vm.inspection.inspectionPoints),function(point){return [(point.type == 'LinesSumInspectionPoint')?_c('inspection-point-result-item',{key:point.id,attrs:{"passed":point.passed,"passed-text":_vm.T(
                'Web.InvoiceInspectionsPage.InspectionPoints.LinesSumInspectionPoint.PassedText',
                'Line sum is correct on all lines'
              ),"failed-text":_vm.T(
                'Web.InvoiceInspectionsPage.InspectionPoints.LinesSumInspectionPoint.FailedText',
                'The sum of one or more lines is incorrect'
              )}},[[_c('lines-sum-check-results',{attrs:{"checks":point.lineSumInspectionChecks},on:{"line-higlighed":function (lineId) { return _vm.handleHightligtInvoiceLine(lineId); }},model:{value:(_vm.reportContent.lineSumChecksToInclude),callback:function ($$v) {_vm.$set(_vm.reportContent, "lineSumChecksToInclude", $$v)},expression:"reportContent.lineSumChecksToInclude"}})]],2):_vm._e(),(point.type == 'LinesAgreementComplianceInspectionPoint')?_c('inspection-point-result-item',{key:point.id,attrs:{"passed":point.passed,"passed-text":_vm.T(
                'Web.InvoiceInspectionsPage.InspectionPoints.LinesAgreementComplianceInspectionPoint.PassedText',
                'All lines compiles to agreed prices'
              ),"failed-text":_vm.T(
                'Web.InvoiceInspectionsPage.InspectionPoints.LinesAgreementComplianceInspectionPoint.FailedText',
                'One or more line(s) does not comply with agreed prices'
              )}},[[_c('lines-agreement-compliance-check-results',{attrs:{"checks":point.lineAgreementsComplianceChecks},on:{"line-higlighed":function (lineId) { return _vm.handleHightligtInvoiceLine(lineId); }},model:{value:(
                  _vm.reportContent.lineAgreementsComplianceChecksToInclude
                ),callback:function ($$v) {_vm.$set(_vm.reportContent, "lineAgreementsComplianceChecksToInclude", $$v)},expression:"\n                  reportContent.lineAgreementsComplianceChecksToInclude\n                "}})]],2):_vm._e(),(point.type == 'ZeroWastecollectionsInspectionPoint')?_c('inspection-point-result-item',{key:point.id,attrs:{"passed":point.passed,"warning":point.passedWithWarnings,"passed-text":_vm.T(
                'Web.InvoiceInspectionsPage.InspectionPoints.ZeroWastecollectionsInspectionPoint.PassedText',
                'No empty waste collections detected'
              ),"failed-text":_vm.T(
                'Web.InvoiceInspectionsPage.InspectionPoints.ZeroWastecollectionsInspectionPoint.FailedText',
                'One or more empty waste collection(s) detected'
              )}},[[_c('zero-waste-collections-warnings-result',{attrs:{"warnings":point.zeroWasteCollectionWarnings},on:{"line-higlighed":function (lineId) { return _vm.handleHightligtInvoiceLine(lineId); }}})]],2):_vm._e()]})],2),_c('b-col',{staticClass:"scroll-col",attrs:{"cols":"7"}},[_c('invoice-preview',{attrs:{"invoice-id":_vm.inspection.invoiceId,"invoice-line-row-class":_vm.invoiceLineRowClass}})],1),_c('div',{staticClass:"action-bar"},[_c('b-card',[_c('div',{staticClass:"inner"},[_c('span',[_vm._v(" "+_vm._s(_vm.T( "Web.InvoiceInspectionsPage.CreditableAmount", "Creditable amount" ))),_c('span',{staticClass:"text-primary font-weight-bold creditable-amount"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.creditableAmount)))])]),_c('span',{staticClass:"submit-con"},[_c('b-form-checkbox',{model:{value:(_vm.downloadOnApprove),callback:function ($$v) {_vm.downloadOnApprove=$$v},expression:"downloadOnApprove"}},[_vm._v(" "+_vm._s(_vm.T( "Web.InvoiceInspectionsPage.DownloadReportOnApprove", "Download report on approve" ))+" ")]),_c('b-button',{staticClass:"dark-btn ml-1",attrs:{"variant":"secondary","disabled":_vm.submitting},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.submitting ? _vm.T("Web.Generic.Submitting", "Submitting") : _vm.T( "Web.InvoiceInspectionsPage.ConfirmGenerateReport", "Confirm & generate report" )))])],1)])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }