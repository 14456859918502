<template>
  <transition name="fade" mode="out-in">
    <b-overlay :show="submitting">
      <div class="d-flex justify-content-center mt-2 mb-2" v-if="isLoading">
        <b-spinner />
      </div>
      <b-row v-else>
        <b-col cols="5" class="scroll-col" style="border-right: 2px solid #ccc">
          <h3 class="text-muted mb-2">
            {{
              T(
                "Web.InvoiceInspectionsPage.Preview.InspectionResults",
                "Inspection results"
              )
            }}
          </h3>
          <template v-for="point in inspection.inspectionPoints">
            <inspection-point-result-item
              :key="point.id"
              v-if="point.type == 'LinesSumInspectionPoint'"
              :passed="point.passed"
              :passed-text="
                T(
                  'Web.InvoiceInspectionsPage.InspectionPoints.LinesSumInspectionPoint.PassedText',
                  'Line sum is correct on all lines'
                )
              "
              :failed-text="
                T(
                  'Web.InvoiceInspectionsPage.InspectionPoints.LinesSumInspectionPoint.FailedText',
                  'The sum of one or more lines is incorrect'
                )
              "
            >
              <template>
                <lines-sum-check-results
                  :checks="point.lineSumInspectionChecks"
                  v-model="reportContent.lineSumChecksToInclude"
                  @line-higlighed="
                    (lineId) => handleHightligtInvoiceLine(lineId)
                  "
                />
              </template>
            </inspection-point-result-item>
            <inspection-point-result-item
              :key="point.id"
              v-if="point.type == 'LinesAgreementComplianceInspectionPoint'"
              :passed="point.passed"
              :passed-text="
                T(
                  'Web.InvoiceInspectionsPage.InspectionPoints.LinesAgreementComplianceInspectionPoint.PassedText',
                  'All lines compiles to agreed prices'
                )
              "
              :failed-text="
                T(
                  'Web.InvoiceInspectionsPage.InspectionPoints.LinesAgreementComplianceInspectionPoint.FailedText',
                  'One or more line(s) does not comply with agreed prices'
                )
              "
            >
              <template>
                <lines-agreement-compliance-check-results
                  :checks="point.lineAgreementsComplianceChecks"
                  v-model="
                    reportContent.lineAgreementsComplianceChecksToInclude
                  "
                  @line-higlighed="
                    (lineId) => handleHightligtInvoiceLine(lineId)
                  "
                />
              </template>
            </inspection-point-result-item>
            <inspection-point-result-item
              :key="point.id"
              v-if="point.type == 'ZeroWastecollectionsInspectionPoint'"
              :passed="point.passed"
              :warning="point.passedWithWarnings"
              :passed-text="
                T(
                  'Web.InvoiceInspectionsPage.InspectionPoints.ZeroWastecollectionsInspectionPoint.PassedText',
                  'No empty waste collections detected'
                )
              "
              :failed-text="
                T(
                  'Web.InvoiceInspectionsPage.InspectionPoints.ZeroWastecollectionsInspectionPoint.FailedText',
                  'One or more empty waste collection(s) detected'
                )
              "
            >
              <template>
                <zero-waste-collections-warnings-result
                  :warnings="point.zeroWasteCollectionWarnings"
                  @line-higlighed="
                    (lineId) => handleHightligtInvoiceLine(lineId)
                  "
                />
              </template>
            </inspection-point-result-item>
          </template>
          <!--
        <inspection-point-result-item
          :passed="false"
          :warning="true"
          passed-text="No duplicated invocing detected"
          failed-text="Possible duplicated invocing detected"
        >
          <template>
            <duplicate-invoice-check-results />
          </template>
        </inspection-point-result-item>
        -->
        </b-col>
        <b-col cols="7" class="scroll-col">
          <invoice-preview
            :invoice-id="inspection.invoiceId"
            :invoice-line-row-class="invoiceLineRowClass"
          />
        </b-col>
        <div class="action-bar">
          <b-card>
            <div class="inner">
              <span>
                {{
                  T(
                    "Web.InvoiceInspectionsPage.CreditableAmount",
                    "Creditable amount"
                  )
                }}<span class="text-primary font-weight-bold creditable-amount">
                  {{ creditableAmount | toCurrency }}</span
                ></span
              >
              <span class="submit-con">
                <b-form-checkbox v-model="downloadOnApprove">
                  {{
                    T(
                      "Web.InvoiceInspectionsPage.DownloadReportOnApprove",
                      "Download report on approve"
                    )
                  }}
                </b-form-checkbox>
                <b-button
                  variant="secondary"
                  class="dark-btn ml-1"
                  :disabled="submitting"
                  @click="handleSubmit"
                  >{{
                    submitting
                      ? T("Web.Generic.Submitting", "Submitting")
                      : T(
                          "Web.InvoiceInspectionsPage.ConfirmGenerateReport",
                          "Confirm & generate report"
                        )
                  }}</b-button
                ></span
              >
            </div>
          </b-card>
        </div>
      </b-row>
    </b-overlay>
  </transition>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BCard,
  BButton,
  BOverlay,
  BFormCheckbox,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import InspectionPointResultItem from "./components/InspectionPointResultItem.vue";
import InvoicePreview from "./components/InvoicePreview.vue";
import LinesSumCheckResults from "./components/LinesSumCheckResults.vue";
import LinesAgreementComplianceCheckResults from "./components/LinesAgreementComplianceCheckResults.vue";
import DuplicateInvoiceCheckResults from "./components/DuplicateInvoiceCheckResults.vue";
import InvoiceInspectionsService from "@/services/economics/invoice-inspections.service";
import ZeroWasteCollectionsWarningsResult from "./components/ZeroWasteCollectionsWarningsResult.vue";
export default {
  components: {
    BRow,
    BCard,
    BCol,
    BButton,
    BOverlay,
    BFormCheckbox,
    BSpinner,
    InvoicePreview,
    LinesSumCheckResults,
    InspectionPointResultItem,
    LinesAgreementComplianceCheckResults,
    InvoicePreview,
    DuplicateInvoiceCheckResults,
    ZeroWasteCollectionsWarningsResult,
  },
  async created() {
    await this.fetchInspection({ id: this.$route.params.id });
    this.reportContent = {
      lineSumChecksToInclude: this.failedLineSumChecks.map((c) => c.checkId),
      lineAgreementsComplianceChecksToInclude:
        this.failedLineAgreementsComplianceChecks.map((c) => c.checkId),
    };
  },
  data() {
    return {
      highlightedLineId: null,
      submitting: false,
      reportContent: null,
      downloadOnApprove: true,
    };
  },
  computed: {
    ...mapGetters({
      getInspectionById: "invoiceInspections/byId",
      isLoading: "invoiceInspections/isLoading",
    }),
    inspection() {
      return this.getInspectionById(this.$route.params.id);
    },
    failedLineSumChecks() {
      return this.inspection.inspectionPoints
        .find((p) => p.type == "LinesSumInspectionPoint")
        .lineSumInspectionChecks.filter((c) => c.isSuccess == false);
    },
    failedLineAgreementsComplianceChecks() {
      return this.inspection.inspectionPoints
        .find((p) => p.type == "LinesAgreementComplianceInspectionPoint")
        .lineAgreementsComplianceChecks.filter((c) => c.isSuccess == false);
    },
    creditableAmount() {
      return (
        this.failedLineSumChecks
          .filter((c) =>
            this.reportContent.lineSumChecksToInclude.includes(c.checkId)
          )
          .reduce(
            (partialSum, c) =>
              partialSum + (c.foundLineTotal - c.expectedLineTotal),
            0
          ) +
        this.failedLineAgreementsComplianceChecks
          .filter((c) =>
            this.reportContent.lineAgreementsComplianceChecksToInclude.includes(
              c.checkId
            )
          )
          .reduce(
            (partialSum, c) =>
              partialSum + (c.lineTotalFound - c.lineTotalExpected),
            0
          )
      );
    },
  },
  methods: {
    ...mapActions({ fetchInspection: "invoiceInspections/fetchSingle" }),
    handleHightligtInvoiceLine(lineId) {
      this.highlightedLineId = lineId;
      let elm = document.querySelector(`tr[data-pk="${lineId}"]`);
      if (elm != undefined) {
        elm.scrollIntoView();
      }
    },
    invoiceLineRowClass(item, type) {
      if (item && type !== "row") return;
      if (item.id == this.highlightedLineId) return "table-warning";
      if (this.lineIdIsInFailedCheck(item.id)) return "table-danger";
      return "table-primary";
    },
    lineIdIsInFailedCheck(lineId) {
      const lineSumChecks = this.inspection.inspectionPoints.find(
        (p) => p.type == "LinesSumInspectionPoint"
      );
      const agreementChecks = this.inspection.inspectionPoints.find(
        (p) => p.type == "LinesAgreementComplianceInspectionPoint"
      );
      if (
        lineSumChecks.lineSumInspectionChecks.some(
          (c) =>
            !c.isSuccess &&
            c.lineId == lineId &&
            this.reportContent.lineSumChecksToInclude.includes(c.checkId)
        ) ||
        agreementChecks.lineAgreementsComplianceChecks.some(
          (c) =>
            !c.isSuccess &&
            c.lineId == lineId &&
            this.reportContent.lineAgreementsComplianceChecksToInclude.includes(
              c.checkId
            )
        )
      )
        return true;

      return false;
    },
    async handleSubmit() {
      try {
        this.submitting = true;
        const service = new InvoiceInspectionsService();
        const data = {
          lineSumChecksToIncludeInReport:
            this.reportContent.lineSumChecksToInclude,
          lineAgreementComplianceToIncludeInReport:
            this.reportContent.lineAgreementsComplianceChecksToInclude,
        };
        await service.approve(this.$route.params.id, data);
        if (this.downloadOnApprove)
          await service.getReport(this.$route.params.id);
        this.$router.push({ name: "economics.invoice-inspections.list" });
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-btn {
  background-color: #000 !important;
}
.scroll-col {
  height: calc(100vh - 217px);
  overflow-y: auto;
  scroll-behavior: smooth;
}
.submit-con {
  display: flex;
  align-items: center;
}
.action-bar {
  position: sticky;
  bottom: 10px;
  z-index: 9999;
  width: 100%;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .creditable-amount {
      font-size: 20px;
    }
  }
}
</style>