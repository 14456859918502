import BaseService from "../base/base.service";

export default class InvoiceInspectionsService extends BaseService {
    constructor() {
        super('economics/invoice-inspections')
    }

    async approve(id, data)
    {
        await this.post(`${id}/approve`, data);
    }

    async getReport(id)
    {
        return await this.getFile(`economics/invoice-inspections/${id}/report`);
    }

    async rerunForInvoice(invoiceId)
    {
        await this.post(`${invoiceId}/rerun`);
    }
}