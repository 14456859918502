<template>
  <span>
    <p>
      Coincidence found with
      <span class="font-weight-bold">invoice #1006994</span>
      invoiced to "<span class="font-weight-bold">Kolding Storcenter</span>" by
      "<span class="font-weight-bold">BioTrans Service ApS</span>" within the same month.
    </p>
    <b-button
      variant="primary"
      target="_blank"
      :to="{
        name: 'economics.invoices.preview',
        params: { id: 'd5d9648d-eb75-4f55-b164-96f7e9460a52' },
      }"
      @click.stop
    >
      Show duplicate invoice
    </b-button>
  </span>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  components: {
    BButton,
  },
};
</script>