<template>
  <b-card @click="collapsed = !collapsed">
    <div class="inner">
      <div class="header">
        <span>
          <h1>{{ passed && !warning ? passedText : failedText }}</h1>
          <small>{{(collapsed) ? T('Web.InvoiceInspectionsPage.Preview.ClickToOpenResults', 'Click to see results') : T('Web.InvoiceInspectionsPage.Preview.ClickToCloseResults', 'Click to close result list') }}</small>
        </span>
        <parsed-failed-icon :passed="passed" :warning="warning" />
      </div>
      <div class="body" v-if="!collapsed">
        <slot> </slot>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import ParsedFailedIcon from "./ParsedFailedIcon.vue";

export default {
  props: {
    passed: {
      required: true,
      type: Boolean,
    },
    warning:{
      required: false,
      default: false
    },
    passedText: {
      required: true,
      type: String,
    },
    failedText: {
      required: true,
      type: String,
    },
  },
  components: {
    BCard,
    ParsedFailedIcon,
  },
  data() {
    return {
      collapsed: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  &:hover {
    background: #ebe9f1;
    cursor: pointer;
  }
  .card-body {
    .inner {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          font-size: 20px;
          margin-bottom: 0px;
        }
      }
      .body {
          margin-top: 10px;
          padding-top: 10px;
        border-top: 1px solid #ebe9f1;
      }
    }
  }
}
</style>