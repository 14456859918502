<template>
    <div>
        <feather-icon v-if="passed && warning" icon="AlertTriangleIcon" size="25" class="text-warning" />
        <feather-icon v-else-if="!passed" icon="AlertCircleIcon" size="25" class="text-danger" />
        <feather-icon v-else icon="CheckIcon" size="25" class="text-success" />
    </div>
</template>

<script>
export default {
    props:{
        passed: {
            required: true,
            type: Boolean
        },
        warning: {
            required: false,
            type: Boolean,
            default: false
        }
    }
}
</script>